import React from 'react';
import { Routes, Route } from "react-router-dom";
import Homepage from '../HomePage'
import ContactPage from '../ContactPage/ContactPage';
import AppoinmentPage from '../AppoinmentPage/AppoinmentPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import CaseGallery from '../CaseGallery/CaseGallery';
import AllTeamMembersPage from '../TeamPage/AllTeamMembersPage';
import FaQPage from '../FaQPage/FaQPage';
import InsurancePage from '../InsurancePage/InsurancePage';
import DoctorProfilePage from '../DoctorProfilePage/DoctorProfilePage';
import LocationPage from '../LocationPage/LocationPage';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const AllRoute = () => {
  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  console.log(canonicalUrl)
  return (
    <div className="App">
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='appoinment' element={<AppoinmentPage />} />
          <Route path='dr-tran' element={<DoctorProfilePage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='/404' element={<ErrorPage />} />
          <Route path='/*' element={<ErrorPage />} />
       
          <Route path='case-gallery' element={<CaseGallery />} />
          <Route path='all-team-members' element={<AllTeamMembersPage />} />
          <Route path='faq' element={<FaQPage />} />
          <Route path='insurance' element={<InsurancePage />} />
          <Route path='location' element={<LocationPage />}/>
        </Routes>

    </div>
  );
}

export default AllRoute;
