import React from "react";
import { Link } from 'react-router-dom'
import officeInfo from "../../api/addressInfo";
import img from "../../images/clinic/exterior.jpg"
// const Welcome = (props) => {

//     const ClickHandler = () => {
//         window.scrollTo(10, 0);
//     }

//     return (
//         <section className={`wpo-welcome ${props.class}`}>
//             <div className="container">
//                 <div className="row justify-content-center">
//                     <div className="col ">
//                         <div className="wpo-breadcumb-wrap">
//                             <h2>WELCOME TO UP DENTAL OF MANVEL</h2>
                          
//                              <div className="welcome-btns">
//                                 <Link onClick={ClickHandler} to="/appoinment" className="theme-btn">Book Appointment</Link>
//                                 <a href={`tel:${officeInfo.phone}`} className="theme-btn-accent-big">Call {officeInfo.phone}</a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }

const Welcome = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className={`wpo-welcome section-padding ${props.class}`}>
            <img className="background-img-container" src={img} alt="dental_clinic_exterior_img"></img>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col ">
                        <div className="wpo-breadcumb-wrap">
                            {/* <h2>WELCOME TO UP DENTAL OF MANVEL</h2> */}
                            <h1>WELCOME TO UP DENTAL OF MANVEL</h1>
                          
                             <div className="welcome-btns">
                                <Link onClick={ClickHandler} to="/appoinment" className="theme-btn">Book Appointment</Link>
                                <a href={`tel:${officeInfo.phone}`} className="theme-btn-accent-big">Call {officeInfo.phone}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome;