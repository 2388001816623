import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar.js'
// import PageTitle from '../../components/pagetitle/PageTitle.js'
import Scrollbar from '../../components/scrollbar/scrollbar.js'
import Footer from '../../components/footer/Footer.js'
import profileImage from '../../images/team/dentist.JPG'
import { OneCaseImagesSwiper } from '../CaseGallery/CaseGallery.js';
import caseGalleryData from '../../api/caseImgs';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const DoctorProfilePage = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Helmet>
                <title>Meet Dr. Tran | Up Dental of Manvel, TX</title>
                <meta name="description" content="Meet the Dentist | Up Dental of Manvel TX" />
                <meta name="keywords" content="dental, dentist, Manvel, treatment, patients, office" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-2'} />
            {/* <PageTitle pageTitle='Meet the Dentist' pagesub='' className={'wpo-page-title'}/> */}
            <div className="team-pg-area section-padding">
                <div className="container">
                    <div className="team-info-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="team-info-img">
                                    <img src={profileImage} alt="Dr Tri Tran DMD, dentist" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="team-info-text">
                                    <h2>Dr. Tri Tran, D.M.D</h2>
                                    <div>
                                        <p>
                                        Dr. Tran completed his undergraduate studies as a double major Magna Cum Laude in Biological Science and Chemistry at University of California, Irvine (UCI). Deeply interested in material science and healthcare, he went on to complete his Doctor of Dental Medicine training program at University of Nevada, Las Vegas (UNLV). Thanks to the early exposure to many aspects of dentistry in UNLV, Dr. Tran tries hard to keep updated with modern dentistry via continuing education. Some highlighted examples are 300 hours CE course in implant dentistry of Maxicourse AAID, 200 hours CE course in comprehensive dentistry of Spear Education, and 40 hours of CE course in implant dentistry of Hiossen Education.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="exprience-area">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div className="exprience-wrap">
                                    <p> During his off time, Dr. Tran enjoys spending time with family and his dogs, Coco and Latte.</p>
                                </div> */}
                                {/* <div className="at-progress">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress yellow">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">25+</div>
                                                <div className="progress-name"><span>Award</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress blue">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">90%</div>
                                                <div className="progress-name"><span>Happy Client</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress pink">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">95%</div>
                                                <div className="progress-name"><span>Project Done</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress green">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">1000+</div>
                                                <div className="progress-name"><span>Project</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="education-area ex-wiget">
                                    <h2>Education</h2>
                                    <ul>
                                        <li>BS in Chemistry & Biological Science, UC Irvine, 2015 </li>
                                        <li>DMD, UNLV (University of Nevada, Las Vegas) Dental School, 2020</li>
                                    </ul>
                                </div>
                                <div className="education-area ex-wiget">
                                    <h2>Language</h2>
                                    <ul>
                                        <li> English  </li>
                                        <li>Vietnamese</li>
                                    </ul>
                                </div>
                                {/* PUT PORFOLIO HERE */}
                                <div className="wpo-contact-area ex-wiget certificate">
                                    <h2>Treatment By Dr. Tran, DMD</h2>
                                    <OneCaseImagesSwiper  images={caseGalleryData[4].images} />
                                    <div className='btn-container' id="more-case-button" >
                                        <Link onClick={ClickHandler} to="/case-gallery">
                                            <button type="submit" className="theme-btn-accent"> More Case Pictures</button>
                                        </Link>                                          
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default DoctorProfilePage;
