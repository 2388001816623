import React from "react";

import 'swiper/css';
import 'swiper/css/navigation';
import Slider from "react-slick";
import { oneSliderSetting } from "../../api/sliderSetting";

import clinicImg1 from '../../images/clinic/treatment_room.jpg'
import clinicImg2 from '../../images/clinic/hallway.jpg'
import clinicImg3 from '../../images/clinic/kid_room.jpg'
import clinicImg4 from '../../images/clinic/xray_room.jpg'

const clinicInfo = [
    {
        imgSrc: clinicImg2,
        description: "All treatment/hygiene rooms are spacious private rooms with doors for privacy. No need to hear distractions such as footsteps and unrelated hallway conversations",
        imgDescription:"hallway_room_pic"
    },
    {
        imgSrc:clinicImg1,
        description: "Our open-space design concept will give our patients a sense of calmness. With one TV mounted on the wall and another TV mounted on the ceiling, our patients are kept being entertained throughout the treatment time.",
        imgDescription:"treatment_room_pic"
    }, 
    {
        imgSrc:clinicImg4,
        description:"In house CBCT (Cone Beam Xray) for implant dentistry and Ceph (Cephalometric Xray) for braces are ready to limit external referral to nearby imaging centers",
        imgDescription:"xray_room_pic"
    },
    {
        imgSrc: clinicImg3,
        description:"A separate playroom for kids and adolescents is equipped with age appropriate toys/games so parents can have an easier time convincing the young patients to come and to enjoy their dental visits.",
        imgDescription:"kid_playground_pic"
    }
]
// const OneSlide = ({imgSrc, description}) => {
//     return (
//         <div className="row align-items-center" style={{marginBottom:'2rem'}}>
//             <div className="col-lg-5 col-md-12 col-12">
//                 <div className="wpo-about-text">
//                     <p style={{fontSize: '1.3rem'}}>
//                         {description}
//                     </p>
   
//                 </div>
//             </div>

//             <div className='col-lg-7 col-md-12 col-12' >
//                 <div key="imgIdx" className='img-holder'>
//                     <img src={imgSrc} alt="" ></img>
//                 </div>
//             </div>
            
//         </div>
//     )
// }

const OneSlideStyle2 = ({imgSrc, description, imgDescription}) => {
    return (
        <div className="row align-items-center" >

            <div className='slide-image-container'>
                <div key="imgIdx" className='img-holder'>
                    <img src={imgSrc} alt={imgDescription} ></img>
                </div>
            </div>
            
            <div className="wpo-section-title">
                <p >
                    {description}
                </p>

            </div>

        </div>
    )
}

const OfficeHeroStyle2 = (props) => {

    
    return (
        <section  className={`wpo-hero-section section-padding ${props.class}`}>
            <div className="container">
                {/* HEADER */}
                <div className="row justify-content-center" >
                        <div className="col-lg-10">
                            <div className="wpo-section-title">
                                <span>Our Dental Office</span>
                                {/* <h2>Tour Our Office</h2> */}
                                <h1>Tour Our Office</h1>
                               
                            </div>
                        </div>
                </div>
                <div className="slide-container">
                    <Slider {...oneSliderSetting} >
                        {
                            clinicInfo.map((info, idx) =>(
                                <OneSlideStyle2 key={idx} imgSrc={info.imgSrc} description={info.description} imgDescription={info.imgDescription}/>
                            ))
                        }
                    </Slider>
                </div>

                {/* <div className="slide-container" >
                    <Slider {...oneSliderSetting} >
                        {
                            clinicInfo.map((info, idx) =>(
                                <OneSlide key={idx} imgSrc={info.imgSrc} description={info.description}/>
                            ))
                        }
                    </Slider>
                </div> */}
                
            </div>
        </section>
    )
}

export default OfficeHeroStyle2;