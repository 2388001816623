import timg1 from '../images/team/dentist_.JPG'
import timg2 from '../images/team/Vanessa.jpg' 
import timg3 from '../images/team/Kaylee.jpg'
import timg4 from '../images/team/Hannah.jpg'
// import timg5 from '../images/team/employee4.jpeg'
// import timg6 from '../images/team/employee5.jpeg'
const Teams = [
   {
      id: '1',
      tImg: timg1,
      name: 'Dr. Tri Tran',
      title: 'D.M.D',
   },
   {
      id: '2',
      tImg: timg2,
      name: 'Vanessa Lunford',
      title: 'Office Manager',
   },
   {
      id: '3',
      tImg: timg3,
      name: 'Kaylee Graves',
      title: 'Front Desk',
   },
   {
      id: '4',
      tImg: timg4,
      name: 'Hannah Caballero',
      title: 'Registered Dental Assistant',
   },
   // {
   //    id: '5',
   //    tImg: timg5,
   //    name: 'Andrew Silva',
   //    title: 'dental assistant',
   // },
   // {
   //    id: '6',
   //    tImg: timg6,
   //    name: 'David Miller',
   //    title: 'hygenist',
   // },
]

export default Teams;