import React from 'react';
import AllRoute from '../router'
import { BrowserRouter } from 'react-router-dom'; 
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';

const App = () => { 
  return (
    <div className="App" id='scrool'>
          <BrowserRouter>  {/* Wrap your entire app in BrowserRouter */}
            <AllRoute />    {/* AllRoute now works inside a Router */}
          </BrowserRouter>
          <ToastContainer/>
    </div>
  );
}

export default App;
